// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore, collection } from 'firebase/firestore';
import 'firebase/storage';
import 'firebase/firestore';

//config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);

//initiate storage
const storage = getStorage(app);

//initiate database
const db = getFirestore();

//refs
const usersColRef = collection(db, 'users');
const settingsColRef = collection(db, 'settings');
const ordersColRef = collection(db, 'orders');

export { storage, db, usersColRef, settingsColRef, ordersColRef };
