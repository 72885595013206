import {
  React,
  Store,
  Link,
  useState,
  useContext,
  useEffect,
  v4,
  textContent,
} from '../imports';

import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

const OrderForm = () => {
  const { state, dispatch: contextDispatch } = useContext(Store);

  const [formEmail, setFormEmail] = useState('');
  const [formName, setFormName] = useState('');
  const [formSurname, setFormSurname] = useState('');
  const [formInvoiceRequested, setFormInvoiceRequested] = useState(false);
  const [formInvoiceNumber, setFormInvoiceNumber] = useState('');
  const [formTermsConditionsAccept, setFormTermsConditionsAccept] =
    useState(false);
  const [itemPrice, setItemPrice] = useState(null);
  const [paymentInitiated, setPaymentInitiated] = useState(false);

  //generate unique id used as order id, payment id & save it to context
  useEffect(() => {
    const generateUniqueId = () => {
      try {
        contextDispatch({
          type: 'CACHE_UNIQUE_ID',
          payload: { uniqueId: v4() },
        });
      } catch (err) {
        console.log(err);
      }
    };
    generateUniqueId();
  }, [contextDispatch]);
  // console.log('generateUniqueId: ' + state.cart.uniqueId);

  const getPrice = async () => {
    try {
      const docRef = doc(
        db,
        'settings',
        `${process.env.REACT_APP_FIREBASE_SETTINGS_PRICE}`
      );
      const docSnap = await getDoc(docRef);
      setItemPrice(
        Number(
          docSnap?._document?.data?.value?.mapValue?.fields?.imagePrice
            ?.integerValue
        )
      );
    } catch (err) {
      console.error(err);
    }
  };
  getPrice();

  const [errorMessage, setErrorMessage] = useState('');

  const handleFormEmailUpdate = (e) => {
    setFormEmail(e.target.value);
  };

  const handleFormNameUpdate = (e) => {
    setFormName(e.target.value);
  };

  const handleFormSurnameUpdate = (e) => {
    setFormSurname(e.target.value);
  };

  const toggleInputInvoice = () => {
    setFormInvoiceRequested((prevState) => !prevState);
    setFormInvoiceNumber('');
    setErrorMessage('');
  };

  const handleFormInvoiceFieldChange = (e) => {
    setFormInvoiceNumber(e.target.value);
  };

  const handleFormTermsConditionsAccept = () => {
    setFormTermsConditionsAccept((prevState) => !prevState);
  };

  //validation methods
  const formValidation = () => {
    const emailValidation = () => {
      const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
      if (regEx.test(formEmail.toLowerCase()) === true) {
        setErrorMessage('');
        return true;
      } else {
        setErrorMessage('Proszę wprowadzić poprawny adres email');
      }
    };
    emailValidation();

    const nameValidation = () => {
      if (formName.length > 1 && formName.length < 20) {
        setErrorMessage('');
        return true;
      } else {
        setErrorMessage('Proszę wprowadzić poprawne imię');
      }
    };
    nameValidation();

    const surnameValidation = () => {
      if (formSurname.length > 2 && formSurname.length < 30) {
        setErrorMessage('');
        return true;
      } else {
        setErrorMessage('Proszę wprowadzić poprawne nazwisko');
      }
    };
    surnameValidation();

    const formInvoiceNumberValidation = () => {
      if (formInvoiceRequested === true) {
        if (formInvoiceNumber.length > 7 && formInvoiceNumber.length < 11) {
          setErrorMessage('');
          return true;
        } else {
          setErrorMessage('Proszę wprowadzić poprawny numer NIP');
        }
      } else return true;
    };
    formInvoiceNumberValidation();

    const termsConditionsAcceptValidation = () => {
      if (formTermsConditionsAccept === true) {
        setErrorMessage('');
        return true;
      } else {
        setErrorMessage('Aby kontynuować zaakceptuj warunki regulaminu');
      }
    };
    termsConditionsAcceptValidation();

    if (
      emailValidation() === true &&
      nameValidation() === true &&
      surnameValidation() === true &&
      formInvoiceNumberValidation() === true &&
      termsConditionsAcceptValidation() === true
    ) {
      return true;
    }
  };

  //data submission function
  const initiatePayment = (e) => {
    e.preventDefault();

    // console.log('generated UniqueId: ' + state.cart.uniqueId);

    if (formValidation() === true) {
      setPaymentInitiated(true); //toggle order form and tell the user to continue to the payment
    } else {
      console.log('form invalid');
    }
    handleFormSubmission();
  };

  const calculatedAmount = state?.cart?.cartItems?.length * itemPrice;

  const handleFormSubmission = async () => {
    if (formValidation() === true) {
      try {
        //add order to db
        // console.log('add order to database');

        const orderRef = doc(db, 'orders', state.cart.uniqueId);

        await setDoc(orderRef, {
          email: formEmail,
          name: formName,
          surname: formSurname,
          invoiceRequested: formInvoiceRequested,
          invoiceTaxId: formInvoiceNumber,
          termsConditionsAccepted: formTermsConditionsAccept,
          isPaid: false,
          emailSent: false,
          cartItems: state.cart.cartItems,
          dateCreated: new Date(),
          orderId: state.cart.uniqueId,
          amount: calculatedAmount,
        });

        //initiate payment process: register payment in p24
        paymentRegister();
        setPaymentInitiated(true);
      } catch (err) {
        // setErrorMessage(err.message);
        console.log('error: ' + err);
      }
    } else {
      console.log('form data doesnt meet criteria');
    }
  };

  const paymentRegister = () => {
    //----------------------------------------------------------------
    // Stripe
    //----------------------------------------------------------------
    fetch(process.env.REACT_APP_STRIPE_CREATE_CHECKOUT_SESSION_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
        body: JSON.stringify({
          items: [{ id: 1, quantity: state.cart.cartItems.length, price: itemPrice }],
          uniqueId: state.cart.uniqueId,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          console.log(res);
          return res.json();
        } else return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((err) => {
        console.error(err);
      });

  };

  return (
    <>
      {paymentInitiated === false ? (
        <div className="order-form__container">
          <span>
            <h2>
              {
                textContent[
                  textContent.findIndex((obj) => {
                    return obj.language === state.languageSelected;
                  })
                ]?.orderForm?.return
              }
            </h2>
          </span>
          <div className="order-form__content">
            <form>
              <label>
                {
                  textContent[
                    textContent.findIndex((obj) => {
                      return obj.language === state.languageSelected;
                    })
                  ]?.orderForm?.email
                }
                *:
                <input
                  value={formEmail}
                  onChange={handleFormEmailUpdate}
                  type="email"
                  name="email"
                  required
                />
              </label>
              <div className="order-form__content__group">
                <label>
                  {
                    textContent[
                      textContent.findIndex((obj) => {
                        return obj.language === state.languageSelected;
                      })
                    ]?.orderForm?.name
                  }
                  *:
                  <input
                    value={formName}
                    onChange={handleFormNameUpdate}
                    type="text"
                    name="name"
                    required
                  />
                </label>
                <label>
                  {
                    textContent[
                      textContent.findIndex((obj) => {
                        return obj.language === state.languageSelected;
                      })
                    ]?.orderForm?.surname
                  }
                  *:
                  <input
                    value={formSurname}
                    onChange={handleFormSurnameUpdate}
                    type="text"
                    name="surname"
                    required
                  />
                </label>
              </div>
              <div className="order-form__content__checkbox">
                <input
                  name="consentInvoice"
                  type="checkbox"
                  onChange={toggleInputInvoice}
                />
                <label>
                  {
                    textContent[
                      textContent.findIndex((obj) => {
                        return obj.language === state.languageSelected;
                      })
                    ]?.orderForm?.receiptRequested
                  }
                </label>
              </div>

              {formInvoiceRequested ? (
                <>
                  <div className="order-form__content__checkbox">
                    <label>
                      {
                        textContent[
                          textContent.findIndex((obj) => {
                            return obj.language === state.languageSelected;
                          })
                        ]?.orderForm?.taxId
                      }{' '}
                      <input
                        value={formInvoiceNumber}
                        onChange={handleFormInvoiceFieldChange}
                        type="text"
                        name="phone"
                      />
                    </label>
                  </div>
                </>
              ) : null}

              <div className="order-form__content__checkbox">
                <input
                  name="consentTermsConditions"
                  type="checkbox"
                  onChange={handleFormTermsConditionsAccept}
                />
                <label>
                  {state.languageSelected === 'PL' ? (
                    <>
                      Oświadczam, że znany mi jest
                      <Link to="/regulamin"> Regulamin</Link> oraz
                      <Link to="/polityka-prywatnosci">
                        {' '}
                        Polityka prywatności
                      </Link>{' '}
                      i akceptuję ich postanowienia.*
                    </>
                  ) : (
                    <>
                      I confirm that I have read and accept the
                      <Link to="/regulamin"> Terms and Conditions</Link> as well
                      as the
                      <Link to="/polityka-prywatnosci"> Privacy Policy</Link>
                      .*
                    </>
                  )}
                </label>
              </div>

              <br />
              <div className="form__error-message">
                <span>{errorMessage}</span>
              </div>
              <br />
              <button
                onClick={initiatePayment}
                type="submit"
                value="Zapisz"
                className="btn--primary"
              >
                {
                  textContent[
                    textContent.findIndex((obj) => {
                      return obj.language === state.languageSelected;
                    })
                  ]?.orderForm?.payment
                }
              </button>
              <br />
              <span>
                *{' '}
                {
                  textContent[
                    textContent.findIndex((obj) => {
                      return obj.language === state.languageSelected;
                    })
                  ]?.orderForm?.necessary
                }
              </span>
            </form>
          </div>
        </div>
      ) : (
        <>
          <div className="order-form__container">
            <h2>
              {
                textContent[
                  textContent.findIndex((obj) => {
                    return obj.language === state.languageSelected;
                  })
                ]?.orderForm?.nextStepsHeader
              }
            </h2>
            <br />
            <p>
              {
                textContent[
                  textContent.findIndex((obj) => {
                    return obj.language === state.languageSelected;
                  })
                ]?.orderForm?.nextStepsPara1
              }
            </p>
            <ul>
              {
                textContent[
                  textContent.findIndex((obj) => {
                    return obj.language === state.languageSelected;
                  })
                ]?.orderForm?.nextStepsUl
              }
              <li>
                {
                  textContent[
                    textContent.findIndex((obj) => {
                      return obj.language === state.languageSelected;
                    })
                  ]?.orderForm?.nextStepsLi1
                }
              </li>
              <li>
                {
                  textContent[
                    textContent.findIndex((obj) => {
                      return obj.language === state.languageSelected;
                    })
                  ]?.orderForm?.nextStepsLi2
                }
              </li>
            </ul>
            <p>
              {
                textContent[
                  textContent.findIndex((obj) => {
                    return obj.language === state.languageSelected;
                  })
                ]?.orderForm?.nextStepsLi3
              }
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default OrderForm;
